import React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import {
  Box,
  getResponsiveProp,
  MedicallyReviewedBadge,
  Mq,
  Palette,
  Text,
  theme,
  WebsiteWrapper
} from '@parsleyhealth/cilantro-ui';
import { SanityHiltonPost } from 'graphql-types';
import { useHiltonEducationConfig } from '../../../src/hooks/use-hilton-education-config';
import BlockContent from '@sanity/block-content-to-react';
import BlogAuthor from '../../components/Blog/BlogAuthor';
import BlogFeaturedPosts from '../../components/Blog/BlogFeaturedPosts';
import BlogGrid from '../../components/Blog/blog-grid';
import BlogPostHero from '../../components/Blog/blog-post-hero';
import BlogPostStyles from '../../components/Blog/blog-post-styling';
import BlogShareSocialMedias from '../../components/Blog/blog-share-social-medias';
import BlogSidebarCard from '../../components/Blog/blog-sidebar-card';
import SEO from '../../components/SEO';
import { getBlogPostUrl, getAuthorUrl } from '../../../src/lib/blog-utils';
import { HiltonEducationBanner } from '../../components/HiltonEducation/hilton-education-banner';
import HiltonLayout from '../../components/HiltonEducation/hilton-layout';

interface HiltonEducationPostTemplateProps {
  data: {
    sanityHiltonPost: SanityHiltonPost;
    relatedPosts: {
      edges: {
        node: SanityHiltonPost;
      }[];
    };
  };
}

const ContainerAuthor = styled(Box)`
  border-bottom: 1px solid ${Palette.concrete};
`;

const ContainerPostsRelated = styled(Box)`
  ${Mq(2)} {
    margin-left: ${theme.space.l}px;
  }
`;

const WebMarginsWrapper = styled(WebsiteWrapper)`
  margin-top: ${theme.space.m}px;
  margin-bottom: ${theme.space.m}px;
`;

const VideoContainer = styled(BlogPostStyles)`
  iframe {
    width: 100%;
    height: 220px;
  }

  ${Mq(1)} {
    iframe {
      width: 100%;
      height: 390px;
    }
  }

  ${Mq(3)} {
    iframe {
      width: 100%;
      height: 530px;
    }
  }
`;

const HiltonEducationPostTemplate = ({
  data
}: HiltonEducationPostTemplateProps) => {
  const {
    metaDataHilton,
    SidebarWhatIsPH,
    Sidebar150Off,
    SidebarDisclaimer,
    SidebarFranchiseDisclaimer
  } = useHiltonEducationConfig();

  const post = data?.sanityHiltonPost;

  if (!post) {
    return null;
  }

  const {
    author,
    expertApprover,
    themes,
    content,
    image,
    medicallyReviewed,
    publishedDate,
    slug,
    title,
    metaTitle,
    metaDescription,
    metaImage,
    id,
    noindex,
    videoEmbedCode
  } = post;
  const publishDate = new Date(publishedDate).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });
  const postSiteUrl = `${process.env.GATSBY_SITE_URL}${getBlogPostUrl(
    slug?.current
  )}`;

  const relatedPosts = data?.relatedPosts?.edges
    ?.map(p => p.node)
    .filter(p => p.id !== id && p.author !== null)
    .slice(0, 5)
    .sort(() => Math.random() - 0.5);

  let seoImage = null;
  if (metaImage) {
    seoImage = metaImage;
  } else if (image) {
    seoImage = image;
  }

  const videoEmbedCodeTrimmed = videoEmbedCode?.trim();

  return (
    <>
      <SEO
        pageTitle={metaTitle ? metaTitle : title}
        pageDescription={
          metaDescription ? metaDescription : metaDataHilton.metaDescription
        }
        pageImage={seoImage}
        noindex={noindex}
      />
      <HiltonLayout paddingTop={false}>
        <BlogPostHero
          category={themes && themes.length ? themes[0]?.title : null}
          title={title}
          image={image ?? null}
        />
        <WebMarginsWrapper>
          <BlogGrid>
            <Box>
              <ContainerAuthor pb="l" mb="l">
                <BlogAuthor
                  name={author?.authorByLine}
                  url={getAuthorUrl(author?.slug?.current)}
                  image={author?.profileImage}
                  role={author?.role}
                  size={'ms'}
                />
                {expertApprover && (
                  <div style={{ marginTop: '16px' }}>
                    <BlogAuthor
                      name={expertApprover.authorByLine}
                      url={getAuthorUrl(expertApprover.slug?.current)}
                      image={expertApprover.profileImage}
                      role={'Expert Approver'}
                      size={'ms'}
                      isExpertApprover={true}
                    />
                  </div>
                )}
              </ContainerAuthor>
              {videoEmbedCodeTrimmed?.length > 0 && (
                <VideoContainer
                  dangerouslySetInnerHTML={{ __html: videoEmbedCodeTrimmed }}
                />
              )}
              <Box px={getResponsiveProp('none', 'xxl')}>
                {medicallyReviewed && (
                  <Box mb="s" maxWidth="200px">
                    <MedicallyReviewedBadge />
                  </Box>
                )}
                <Text mb="m">{publishDate}</Text>
                <BlogPostStyles>
                  <BlockContent blocks={content} />
                </BlogPostStyles>
              </Box>
              <ContainerAuthor pb="l" mb="l" mt="l">
                <BlogAuthor
                  name={author?.authorByLine}
                  url={getAuthorUrl(author?.slug?.current)}
                  image={author?.profileImage}
                  role={author?.role}
                  size="l"
                  bio={<BlockContent blocks={author?.bio} />}
                />
              </ContainerAuthor>
              <BlogShareSocialMedias
                url={postSiteUrl}
                pinterestMedia={image?.asset?.fluid?.src}
              />
            </Box>
            <ContainerPostsRelated>
              <BlogSidebarCard card={Sidebar150Off} prefix="siderail-1" />
              <BlogFeaturedPosts
                posts={relatedPosts ?? []}
                title="Related Posts"
              />
              <BlogSidebarCard card={SidebarWhatIsPH} prefix="siderail-2" />
              <BlogSidebarCard card={SidebarDisclaimer} prefix="siderail-2" />
              <BlogSidebarCard
                card={SidebarFranchiseDisclaimer}
                prefix="siderail-2"
              />
            </ContainerPostsRelated>
          </BlogGrid>
        </WebMarginsWrapper>
        <HiltonEducationBanner />
      </HiltonLayout>
    </>
  );
};

export const query = graphql`
  fragment HiltonPostAuthor on SanityPerson {
    authorByLine
    slug {
      current
    }
    profileImage {
      _type
      ...SanityImageFragment
    }
    role
    roleTitle
    bio: _rawBackground
  }

  fragment HiltonRelatedPost on SanityHiltonPost {
    id
    title
    slug {
      current
    }
    author {
      ...HiltonPostAuthor
    }
    expertApprover {
      ...HiltonPostAuthor
    }
    tags {
      slug {
        current
      }
      title
    }
  }

  query HiltonPostQuery($sanityID: String, $categories: String) {
    sanityHiltonPost(_id: { eq: $sanityID }) {
      id
      title
      metaTitle
      metaDescription
      metaImage {
        ...MetaImage
      }
      image {
        _type
        ...SanityImageFragment
      }
      themes {
        id
        title
        slug {
          current
        }
      }
      author {
        ...HiltonPostAuthor
      }
      publishedDate
      content: _rawContent(resolveReferences: { maxDepth: 10 })
      medicallyReviewed
      noindex
      slug {
        current
      }
    }
    relatedPosts: allSanityHiltonPost(
      filter: { themes: { elemMatch: { title: { regex: $categories } } } }
      limit: 9
    ) {
      edges {
        node {
          ...HiltonRelatedPost
        }
      }
    }
  }
`;

export default HiltonEducationPostTemplate;
